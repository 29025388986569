import { useCallback, useEffect, useState, createRef } from "react";
import { useResizeDetector } from 'react-resize-detector';

import { Box, Paper } from "@mui/material";

import { PlotlyRenderManager } from "./Plotly";

function SignalVisualization({dataToRender, height, figureTitle}) {
  const [show, setShow] = useState(false);
  const fig = new PlotlyRenderManager(figureTitle, "en");

  const audioRef = createRef();
  const [currentTrack, setCurrentTrack] = useState(null);

  const handleGraphing = (data) => {
    fig.clearData();

    if (fig.fresh) {
      let ax = fig.subplots(1, 1, {sharey: false, sharex: true});
      fig.setYlabel(`Signal Amplitude (mV)`, {fontSize: 15});
      fig.setXlabel(`Time (ms)`, {fontSize: 15});
      fig.setSubtitle(`RAW Microelectrode Recording`);
      fig.setYlim([-100, 100]);

      fig.setLayoutProps({
        font: {
            color: '#DDDDDD',
            family:'Lato'
        },
        paper_bgcolor: "rgba(0,0,0,0)",
        plot_bgcolor: 'rgba(0,0,0,0)'
      });

      fig.setAxisProps({
        gridcolor: "#AAAAAA",
        linecolor: "#AAAAAA",
        zerolinecolor: "#FFFFFF"
      }, "x");
      
      fig.setAxisProps({
        gridcolor: "#AAAAAA",
        linecolor: "#AAAAAA",
        zerolinecolor: "#FFFFFF"
      }, "y");
  
    };

    if (!data) {
      fig.purge();
      setShow(false);
    } else {
      fig.render();
      setShow(true);

      setCurrentTrack("https://mer-simulation.pages.dev/mer/MER_GPi.wav");
      
    }
  };

  useEffect(() => {
    if (dataToRender) {
      handleGraphing(dataToRender);
    }
    else {
      fig.purge();
      setShow(false);
    }
  }, [dataToRender]);

  useEffect(() => {
    if (currentTrack && audioRef) {
      console.log("playing")
      console.log(audioRef)
      audioRef.current.play();
    }
  }, [currentTrack, audioRef]);

  const onResize = useCallback(() => {
    fig.refresh();
  }, []);

  const {ref} = useResizeDetector({
    onResize: onResize,
    refreshMode: "debounce",
    refreshRate: 50,
    skipOnMount: false
  });

  return (
    <Paper elevation={3} sx={{
      p: 5,
      mr: 3,
      borderRadius: 10,
      backgroundColor: "#2c387e"
    }}>
      <audio src={currentTrack} ref={audioRef} volume={0.5}/>
      <Box ref={ref} id={figureTitle} style={{marginTop: 5, marginBottom: 10, height: height, width: "100%", display: show ? "" : "none"}}/>  
    </Paper>
  );
}

export default SignalVisualization;