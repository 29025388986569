
export const GameController = (function () {
  let midiDevice = null;
  let outputId = "";
  let volume = [
    [[0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0]],
    [[0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0]],
    [[0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0]],
    [[0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0],
    [0,0,0,0,0,0,0,0]]
  ];
  let slice = 0;
  let lastAction = {
    type: "None",
    index: [0,0,0]
  };

  let audioContext = null;

  const init = (midiAccess, portId) => {
    volume = [
      [[0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0]],
      [[3,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0]],
      [[0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0],
      [0,0,0,0,0,0,0,0]],
      [[0,103,103,0,0,0,0,103],
      [0,0,13,9,0,0,0,9],
      [0,0,0,13,13,13,13,9],
      [9,9,0,13,83,13,13,83],
      [9,9,0,5,13,13,13,9],
      [0,11,0,13,9,9,9,0],
      [0,11,13,9,13,9,13,0],
      [0,0,13,9,11,11,9,0]]
    ];

    midiDevice = midiAccess;
    outputId = portId;
    midiDevice.inputs.forEach((entry) => {
      entry.onmidimessage = onMIDIMessage;
    });

    const outputDevice = midiDevice.outputs.get(outputId);
    outputDevice.send([240, 0, 32, 41, 2, 12, 14, 1, 247]); // Enter Programmer Mode
    outputDevice.send([0x90, 89, targetColor.GPi]);
    outputDevice.send([0x90, 79, targetColor.STN]);
    outputDevice.send([0x90, 69, targetColor.Thalamus]);
    outputDevice.send([0x90, 59, targetColor.Outside]);
  };

  const disconnect = () => {
    const outputDevice = midiDevice.outputs.get(outputId);
    outputDevice.send([240, 0, 32, 41, 2, 12, 14, 0, 247]); // Enter Live Mode Mode
  };

  const onMIDIMessage = (event) => {
    let str = `[${event.data.length} bytes]: `;
    for (const character of event.data) {
      str += `${character.toString(10)} `;
    }
    console.log(str);

    if (event.data.length == 3) {
      
      // Pressed and Released
      if (event.data[0] == 176 && event.data[2] == 0) {
        const row = Math.floor(event.data[1] / 10) - 1;
        const col = event.data[1] - (row+1) * 10 - 1;

        if (row == 8) {
          switch (col) {
            case 1:
              if (slice > 0) {
                slice--;
                handleSliceChange();
              }
              break;
            case 0:
              if (slice < 3) {
                slice++;
                handleSliceChange();
              }
              break;
            default: 
              break;
          }
        }
      }
    }
  };

  const resetColor = () => {
    let colorMessage = [240,0,32,41,2,12,3];
    for (let row = 0; row < volume[slice].length; row++) {
      for (let col = 0; col < volume[slice][row].length; col++) {
        colorMessage.push(...[0, (8-row)*10+col+1, 0]);
      }
    }
    colorMessage.push(247);
    const outputDevice = midiDevice.outputs.get(outputId);
    outputDevice.send(colorMessage); // Clear all color
  }

  const handleSliceChange = () => {
    let colorMessage = [240,0,32,41,2,12,3];
    for (let row = 0; row < volume[slice].length; row++) {
      for (let col = 0; col < volume[slice][row].length; col++) {
        colorMessage.push(...[0, (8-row)*10+col+1, volume[slice][row][col]]);
      }
    }
    colorMessage.push(247);
    const outputDevice = midiDevice.outputs.get(outputId);
    outputDevice.send(colorMessage); // Update 8x8 Slices
  };

  return {
    init, disconnect,
  }

})();

export const targetColor = {
  "GPi": 45,
  "STN": 87,
  "Thalamus": 60,
  "Outside": 103,
  "Flashing": 3
};

export const colorPalette = [
  "#616161",
  "#B3B3B3",
  "#DDDDDD",
  "#FFFFFF",
  "#FFB3B3",
  "#FF6161",
  "#DD6161",
  "#B36161",

  "#FFF3D5",
  "#FFB361",
  "#DD8C61",
  "#B37661",
  "#FFEEA1",
  "#FFFF61",
  "#DDDD61",
  "#B3B361",
  
  "#DEFFA1",
  "#C3FF61",
  "#A1DD61",
  "#81B361",
  "#C3FFB3",
  "#61FF61",
  "#61DD61",
  "#61B361",
  
  "#C3FFC3",
  "#61FF8C",
  "#61DD76",
  "#61B36B",
  "#C3FFCC",
  "#60FFCC",
  "#61DDA0",
  "#61B381",

  "#B8F3E7",
  "#60FFE9",
  "#61DDC2",
  "#61B396",
  "#C2F3FF",
  "#61EEFF",
  "#60C7DD",
  "#61A1B3",

  "#C2DDFF",
  "#60C7FF",
  "#61A1DD",
  "#6281B3",
  "#A18CFF",
  "#6161FF",
  "#6161DC",
  "#6161B3",
  
  "#CCB3FF",
  "#A161FF",
  "#5E48A4",
  "#7661B3",
  "#FFB3FF",
  "#FF61FF",
  "#DD60DD",
  "#B361B3",
  
  "#FFB3D5",
  "#FF61C2",
  "#DD60A1",
  "#B3618C",
  "#FF7661",
  "#E9B361",
  "#DEC260",
  "#A1A161",
  
  "#61B361",
  "#61B38C",
  "#618CD5",
  "#6161FF",
  "#61B3B3",
  "#8C61F3",
  "#CCB3C2",
  "#8B7680",
  
  "#FF6161",
  "#F3FFA1",
  "#EEFC61",
  "#CCFF61",
  "#76DD61",
  "#60FFCC",
  "#61E9FF",
  "#61A1FF",
  
  "#8C61FF",
  "#CD61FC",
  "#EE8CDD",
  "#A07661",
  "#FFA162",
  "#DDF962",
  "#D5FF8C",
  "#60FF61",
  
  "#B2FFA0",
  "#CCFCD5",
  "#B2FFF6",
  "#CDE4FF",
  "#A0C2F6",
  "#D5C2F9",
  "#F98CFF",
  "#FF61CC",
  
  "#FFC161",
  "#F3EE60",
  "#E4FF61",
  "#BDAD53",
  "#B3A160",
  "#60BA76",
  "#77C28B",
  "#8181A0",
  
  "#818CCC",
  "#CDAA81",
  "#DD6061",
  "#FAB3A0",
  "#F8BB76",
  "#FFF38B",
  "#E9F8A1",
  "#D5EE76",
  
  "#8181A0",
  "#F9F9D6",
  "#DDFCE4",
  "#E9E9FF",
  "#E4D4FF",
  "#B3B3B3",
  "#D5D5D5",
  "#F9FFFF",
  
  "#E96060",
  "#AA6161",
  "#82F660",
  "#61B361",
  "#F3EE60",
  "#B3A160",
  "#EEC261",
  "#C17760",
];

