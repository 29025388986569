import { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

import { ThemeProvider } from "@mui/material/styles";
import theme from "assets/theme-dark";

import { colorPalette, targetColor } from "assets/LaunchPadX";

import {
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  FormControl,
  MenuItem,
  Icon,
  InputLabel,
  Select,
  Toolbar,
  Typography,
  Paper,
} from "@mui/material";

import { GameController } from "assets/LaunchPadX";

import SignalVisualization from "views/SignalVisualization";

function App() {
  const [midi, setMidi] = useState(null);
  const [activeMidiDevice, setActiveMidiDevice] = useState("");
  const [availableMidiDevices, setAvailableMidiDevices] = useState([]);
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    function onMIDISuccess(midiAccess) {
      console.log("MIDI ready!");
      setMidi(midiAccess);
    }

    function onMIDIFailure(msg) {
      console.error(`Failed to get MIDI access - ${msg}`);
    }

    navigator.requestMIDIAccess({sysex: true}).then(onMIDISuccess, onMIDIFailure);
  }, []);

  useEffect(() => {
    if (!midi) return;

    let availableDevices = [];
    for (const entry of midi.outputs) {
      let output = entry[1];
      availableDevices.push(output);
    }
    if (availableDevices.length > 0) {
      setAvailableMidiDevices([...availableDevices]);
      setActiveMidiDevice(availableDevices[availableDevices.length-1]);
    }
    
  }, [midi]);

  const connectDevice = () => {
    if (activeMidiDevice != "") {
      GameController.init(midi, activeMidiDevice.id);
      setConnected(true);
    }
  };
  
  const disconnectDevice = () => {
    if (activeMidiDevice != "") {
      GameController.disconnect();
      setConnected(false);
    }
  };
  
  const debugFunction = () => {
    if (activeMidiDevice != "") {
      const outputDevice = midi.outputs.get(activeMidiDevice.id);
      outputDevice.send([0x91, 81, targetColor.Flashing]);
    }
  };
  
  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: 'background.default', p: 2 }}
        >
          {connected ? (
            <SignalVisualization dataToRender={true} height={400} figureTitle={"mer-recording"}/>
          ) : null}
        </Box>

        <Drawer
          sx={{
            width: 300,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: 300,
              boxSizing: 'border-box',
            },
          }}
          variant="permanent"
          anchor="right"
        >
          <Typography mt={2} px={2} fontFamily={"Roboto"} color={"lightgray"} fontWeight={"bold"} fontSize={30} textAlign={"center"}>
            {"MER Simulation"}
          </Typography>
          <Divider />
          <Typography px={2} fontFamily={"Lato"} color={"lightgray"} fontWeight={"medium"} fontSize={18}>
            {"Output Device"}
          </Typography>
          <FormControl sx={{ mt: 2, mx: 1, minWidth: 120 }}>
            <Select
              id="MIDI-Output-Device"
              value={activeMidiDevice}
              onChange={(event) => {
                setActiveMidiDevice(event.target.value)
              }}
              sx={{minHeight: 50}}
            >
              {availableMidiDevices.map((output) => {
                return <MenuItem key={output.id} value={output}>
                  {output.name}
                </MenuItem>
              })}
            </Select>
          </FormControl>
          <Divider />
          <Button variant="contained" color={"success"} sx={{mx: 2, backgroundColor: "#60FF61",
            "&:hover": {
              backgroundColor: "#61DD61"
            },
          }}
            onClick={connected ? disconnectDevice : connectDevice}>
            {connected ? "DISCONNCT" : "CONNECT"}
          </Button>
          <Button variant="contained" color={"error"} sx={{mx: 2, backgroundColor: "firebrick",
            "&:hover": {
              backgroundColor: "#822222"
            },
          }} onClick={debugFunction}>
            DEBUG
          </Button>
        </Drawer>
        
      </Box>
    </ThemeProvider>
  );
}

export default App;
